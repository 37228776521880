import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ImageModalContext from '../store/ImageModalContext'

import MagnifierSVG from '../assets/svg/magnifier.svg'

const ExpandableImage = ({ imageData, children, bottomRight }) => {
  const { openImage } = useContext(ImageModalContext)
  const locClass = bottomRight ? 'c-expandableImage__magButton--br' : ''

  return (
    <div className="c-expandableImage">
      <div
        className={`c-expandableImage__magButton ${locClass}`}
        onClick={() => openImage(imageData)}
        aria-hidden="true"
      >
        <MagnifierSVG className="c-icon" />
      </div>
      {children}
    </div>
  )
}

ExpandableImage.defaultProps = {
  bottomRight: false,
}

ExpandableImage.propTypes = {
  imageData: PropTypes.object.isRequired,
  bottomRight: PropTypes.bool,
}

export default ExpandableImage

export const FragmentQuery = graphql`
  fragment SharpImageModalFragment on ImageSharp {
    fluid(maxWidth: 1200, quality: 90) {
      ...GatsbyImageSharpFluid_withWebp
      presentationHeight
      presentationWidth
    }
  }

  fragment DatoImageModalFragment on DatoCmsFileField {
    fluid(maxWidth: 1200) {
      height
      aspectRatio
      base64
      sizes
      src
      srcSet
      presentationWidth: width
    }
  }
`
