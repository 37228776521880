import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import PriceCard from './PriceCard'

const PriceTable = ({ tag, pricing }) => {
  const [activePlan, setActivePlan] = useState('monthly')

  return (
    <section id="pricing" className="c-section c-section--forPricing">
      <div className="c-section__outer container">
        <div className="c-section__tag">{tag}</div>
        <div className="c-section__inner">
          <div className="row mb-5">
            <div className="col-12 col-lg-6 offset-lg-3 px-5">
              <ul className="c-pricePlanToggle mb-2">
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'monthly' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('monthly')}
                >
                  Monthly
                </li>
                <li
                  className={`c-pricePlanToggle__link ${
                    activePlan === 'annually' ? 'is-active' : ''
                  }`}
                  onClick={() => setActivePlan('annually')}
                >
                  Annually*
                </li>
              </ul>
              <p
                className="c-pricePlanSavings"
                dangerouslySetInnerHTML={{ __html: pricing.savings.sub }}
              />
            </div>
          </div>
          <div className="c-priceCards">
            {pricing.cards.map((priceCard) => (
              <PriceCard
                key={`${priceCard.user}_${priceCard.type}`}
                priceCard={priceCard}
                plan={activePlan}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

PriceTable.defaultProps = {
  tag: 'Our pricing',
}

PriceTable.propTypes = {
  tag: PropTypes.string,
}

export default PriceTable

export const FragmentQuery = graphql`
  fragment PricingFragment on MarkdownRemarkFrontmatter {
    pricing {
      savings {
        sub
      }
      cards {
        type
        user
        monthly
        annually
        has
        not
        link
      }
    }
  }
`
