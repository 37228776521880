import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const LeadStreamer = ({ id, tag, title, text, image, imageWidth, fill }) => (
  <section className="c-section c-section--forLeadSteamer">
    <div className="c-wave c-wave--top">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path
          style={{ stroke: 'none', fill: 'white' }}
          d="M-0.84,110.03 C259.87,-110.03 333.80,252.13 500.84,76.47 L500.00,0.00 L0.00,0.00 Z"
        ></path>
      </svg>
    </div>

    <div className="c-section__outer container">
      {tag && (
        <div id={id} className="c-section__tag rounded-top">
          {tag}
        </div>
      )}
      <div className="c-section__inner">
        <div className="c-forLeadSteamer row">
          <div className="col-12 col-lg-6 col-xl-7">
            <h2 className="c-forLeadSteamer__header">{title}</h2>
            {text && (
              <p
                className="c-forLeadSteamer__text"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
          </div>
          <div className="c-forLeadSteamer__icon col-12 col-lg-6 col-xl-5 text-center">
            <img
              className="img-fluid"
              width={imageWidth}
              src={`/images/icons/${image}.svg`}
            />
          </div>
        </div>
      </div>
    </div>

    <div className="c-wave c-wave--bottom">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path
          style={{ stroke: 'none', fill }}
          d="M-0.84,113.97 C271.16,40.95 365.97,59.70 500.84,81.41 L500.00,150.00 L0.00,150.00 Z"
        ></path>
      </svg>
    </div>
  </section>
)

LeadStreamer.defaultProps = { fill: '#ffffff' }

LeadStreamer.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageWidth: PropTypes.number.isRequired,
}

export default LeadStreamer

export const FragmentQuery = graphql`
  fragment LeadStreamerFragment on MarkdownRemarkFrontmatterLead {
    streamer {
      id
      tag
      title
      text
      image
      imageWidth
    }
  }
`
