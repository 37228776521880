import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const LeadSpot = ({ title, header, text, imageFolder, imageWidth }) => (
  <section id="lead-spot" className="c-section c-section--forLeadSpot">
    <div className="c-section__outer container">
      <div className="c-section__inner">
        <div className="c-forLeadSpot row">
          <div className="c-forLeadSpot__body col-12 col-lg-5">
            <h1
              className="c-forLeadSpot__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <h1
              className="c-forLeadSpot__header"
              dangerouslySetInnerHTML={{
                __html: header,
              }}
            />
            {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
          </div>
          <div className="c-forLeadSpot__image col-12 col-lg-7 text-lg-right">
            <img
              src={`/images/solutions/${imageFolder}/Lead-Spot.jpg`}
              srcSet={`/images/solutions/${imageFolder}/Lead-Spot.jpg, /images/solutions/${imageFolder}/Lead-Spot@1.5x.jpg 1.5x, /images/solutions/${imageFolder}/Lead-Spot@2x.jpg 2x`}
              style={{ width: '100%', maxWidth: imageWidth }}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

LeadSpot.defaultProps = { imageWidth: '550px' }

LeadSpot.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string,
  text: PropTypes.string,
  imageFolder: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
}

export default LeadSpot

export const FragmentQuery = graphql`
  fragment LeadSpotFragment on MarkdownRemarkFrontmatterLead {
    spot {
      title
      header
      text
      image
    }
  }
`
