import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'

import Page from '../components/Page'
import SEO from '../components/SEO'
import Schema from '../components/Schema'
import LeadSpot from '../components/solutions/LeadSpot'
import LeadStreamer from '../components/primary/LeadStreamer'
import ExpandableImage from '../components/ExpandableImage'
import PriceTable from '../components/primary/PriceTable'
import DemoVideo from '../components/primary/DemoVideo'
import CallToAction from '../components/primary/CallToAction'
import Newsletter from '../components/Newsletter'

import { isEven } from '../utils/num'

const AllergenNutritionalDataSearchPage = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query AllergenNutritionalDataSearchPageQuery {
      markdownRemark(
        frontmatter: { path: { eq: "/allergen-nutritional-data-search" } }
      ) {
        frontmatter {
          title
          description
          path
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          benefitsYou {
            blurbsOne {
              title
              text
              icon
            }
            blurbsTwo {
              title
              text
              image {
                modal: childImageSharp {
                  ...SharpImageModalFragment
                }
                thumb: childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageBorder
            }
          }
          ...PricingFragment
          ...DemoVideoFragment
          ...CallToActionFragment
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--caterers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        title={pageData.lead.spot.title}
        header={pageData.lead.spot.header}
        imageFolder="allergen-nutritional-data-search"
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left mb-n5">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={cn(
                      'mb-5 col-12 col-md-6 d-flex flex-column justify-content-center',
                      { 'order-md-2': !isEven(i) }
                    )}
                  >
                    <h2 className="mb-4">{blurb.title}</h2>
                    <div className="c-postStreamer__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 text-center">
                    <ExpandableImage
                      imageData={blurb.image.modal}
                      bottomRight={isEven(i)}
                    >
                      <Img
                        fluid={blurb.image.childImageSharp.fluid}
                        title={blurb.title}
                        alt={blurb.title}
                        className="c-postStreamer__image img-fluid"
                      />
                    </ExpandableImage>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-to-you"
        className="c-section c-section--forBenefits c-section--forBenefits--you"
      >
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-forBenefits">
              {pageData.benefitsYou.blurbsOne.map((blurb) => (
                <div key={blurb.title} className="c-forBenefitsInfo row">
                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--icon col-12 col-md-3">
                    <img
                      className="c-forBenefitsInfo__icon img-fluid"
                      src={`/images/icons/${blurb.icon}.svg`}
                    />
                  </div>

                  <div className="c-forBenefitsInfo__block c-forBenefitsInfo__block--text col-12 col-md-7">
                    <h2 className="c-forBenefitsInfo__header">{blurb.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                  </div>
                </div>
              ))}

              {pageData.benefitsYou.blurbsTwo.map((blurb, i) => (
                <div
                  key={blurb.title}
                  className={`c-forBenefitsCard ${
                    !isEven(i) ? 'c-forBenefitsCard--reversed' : ''
                  }`}
                >
                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--text">
                    <h5 className="c-forBenefitsCard__header">{blurb.title}</h5>
                    <div className="c-forBenefitsCard__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>

                  <div className="c-forBenefitsCard__block c-forBenefitsCard__block--image">
                    <Img
                      fluid={blurb.image.thumb.fluid}
                      title={blurb.title}
                      alt={blurb.title}
                      className={`c-forBenefitsCard__image img-fluid ${
                        blurb.imageBorder && 'has-shadowBorder'
                      }`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <PriceTable
        tag={`Allergen & Nutritional Data Search Subscriptions`}
        pricing={pageData.pricing}
      />

      <DemoVideo
        header={pageData.demoVideo.header}
        text={pageData.demoVideo.text}
        buttonText={pageData.demoVideo.buttonText}
        buttonLink={pageData.demoVideo.buttonLink}
        videoUrl={pageData.demoVideo.videoUrl}
      />

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default AllergenNutritionalDataSearchPage
