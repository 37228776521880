import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const PriceCard = ({ priceCard, plan }) => {
  return (
    <div className="c-priceCard">
      <header className="c-priceCardHeader">
        <span className="c-priceCard__type">{priceCard.user}</span>
        <span className="c-priceCard__subType">{priceCard.type}</span>
        <span className="c-priceCard__divider"></span>
        <span className="c-priceCard__price">{priceCard[plan]}</span>
        <span className="c-priceCard__period">
          {plan === 'monthly' ? 'Per month' : 'Per annum, billed in advance'}
        </span>
      </header>

      <ul className="c-priceCard__features">
        {priceCard.has.map((includes) => (
          <li
            key={`includes_${includes}`}
            className="c-priceCard__feature c-priceCard__feature--has"
          >
            <img src="/icons/tick.svg" className="mr-2" />
            {includes}
          </li>
        ))}
        {priceCard.not &&
          priceCard.not.map((excludes) => (
            <li
              key={`excludes_${excludes}`}
              className="c-priceCard__feature c-priceCard__feature--not"
            >
              <img src="/icons/cross-dimmed.svg" className="mr-2" />
              {excludes}
            </li>
          ))}
      </ul>

      {priceCard.link && (
        <Link
          to={priceCard.link}
          state={{ plan }}
          className="c-button c-button--primary"
        >
          Sign up
        </Link>
      )}

      <span className="c-priceCard__cancel">Cancel anytime</span>
    </div>
  )
}

PriceCard.defaultProps = {
  plan: 'monthly',
}

PriceCard.propTypes = {
  plan: PropTypes.string,
}

export default PriceCard
