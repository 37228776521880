import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

const DemoVideo = ({
  header,
  text,
  blurb,
  buttonText,
  buttonLink,
  videoUrl,
}) => (
  <section className="c-section c-section--forDemoVideo">
    <div className="c-section__outer container">
      <div className="c-section__inner">
        <div className="c-forDemoVideo">
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="c-forDemoVideo__text">
                <h1>{header}</h1>
                {text && <p>{text}</p>}
                <div className="d-none d-lg-block">
                  <div className="c-forDemoVideo__divider">
                    <span></span>
                  </div>
                  <div className="c-forDemoVideo__ctaBlock">
                    <h3 className="mb-4">{blurb}</h3>
                    <a
                      href={buttonLink}
                      className="c-button c-button--primary"
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8 d-lg-flex align-items-center">
              <div className="c-forDemoVideo__video embed-responsive embed-responsive-16by9">
                <iframe
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; fullscreen; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div className="col-12 col-lg-4 d-lg-none">
              <div className="c-forDemoVideo__text">
                <div className="c-forDemoVideo__divider">
                  <span></span>
                </div>
                <div className="c-forDemoVideo__ctaBlock">
                  <h3 className="mb-4">{blurb}</h3>
                  <a
                    href={buttonLink}
                    className="c-button c-button--primary"
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {buttonText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

DemoVideo.defaultProps = {
  blurb: `Ready to find out how Erudus can work for you?`,
}

DemoVideo.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  blurb: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
}

export default DemoVideo

export const FragmentQuery = graphql`
  fragment DemoVideoFragment on MarkdownRemarkFrontmatter {
    demoVideo {
      header
      text
      blurb
      buttonText
      buttonLink
      videoUrl
    }
  }
`
