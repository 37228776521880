import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import cn from 'classnames'

const CallToAction = ({ ctaData, fill }) => (
  <section id="sign-up" className="c-section c-signUpCta">
    <div className="c-wave c-wave--top">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        className={cn(ctaData.wave)}
      >
        <path
          style={{ stroke: 'none' }}
          d="M-0.84,110.03 C259.87,-110.03 333.80,252.13 500.84,76.47 L500.00,0.00 L0.00,0.00 Z"
        ></path>
      </svg>
    </div>

    <div className="container">
      <div className="row">
        <div className="col-12">
          <img
            src={`/images/icons/${ctaData.icon}.svg`}
            // alt={`${ctaData.title} icon`}
            className="c-signUpCta__icon img-fluid"
          />
          <h1 className="c-signUpCta__header">{ctaData.text}</h1>
          <Link
            to={ctaData.link}
            className="c-button c-button--primary"
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {ctaData.button ? ctaData.button : `Sign up`}
          </Link>
        </div>
      </div>
    </div>

    <div className="c-wave c-wave--bottom">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path
          style={{ stroke: 'none', fill }}
          d="M-0.84,113.97 C271.16,40.95 365.97,59.70 500.84,81.41 L500.00,150.00 L0.00,150.00 Z"
        ></path>
      </svg>
    </div>
  </section>
)

CallToAction.defaultProps = { fill: '#ffffff' }

CallToAction.propTypes = {
  ctaData: PropTypes.objectOf(PropTypes.string).isRequired,
  fill: PropTypes.string,
}

export default CallToAction

export const FragmentQuery = graphql`
  fragment CallToActionFragment on MarkdownRemarkFrontmatter {
    signUpCta {
      wave
      icon
      text
      button
      link
    }
  }
`
